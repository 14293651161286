module.exports = {
	languages: {
		// Default language to be redirected to when no locale is specified .e.g / > /fr
		default: "fr",
		// Enables languages. Drupal must have these enabled.
		enabled: ["fr", "ar", "Tfng", "en", "it", "es", "nl", "de"],
		// enabled: ["fr"],
		// Languages labels, mostly used by dropdowns.
		labels: [
			{ code: "en", label: "English" },
			{ code: "fr", label: "Français" },
			{ code: "ar", label: "العربية" },
			// { code: "Tfng", label: "ⵜⴰⵎⴰⵣⵉⵖⵜ" },
		],
	},
	externalLangs: ["it", "es", "nl", "de"],
	// List of menus used by frontend (useMenu hook). Must exists in Drupal.
	menus: ["main", "menu-top", "footer", "footer-bottom", "topmenu", "bottom"],
	images: {
		domains: [process.env.NEXT_IMAGE_DOMAIN, "vactory8.lndo.site", "localhost"],
		dangerouslyAllowSVG: true,
		// contentSecurityPolicy: "default-src 'self'; script-src 'none'; sandbox;",
		deviceSizes: [640, 750, 328, 828, 1080, 1200, 1920],
		imageSizes: [16, 32, 48, 64, 96, 128, 256, 384],
		formats: ["image/webp"],
		minimumCacheTTL: 60,
	},
	performance: {
		widgets: {
			mode: "all", // all or selective
			list: [],
		},
	},
	// Security tweaks.
	security: {
		// Default headers.
		headers: [
			{
				key: "X-DNS-Prefetch-Control",
				value: "on",
			},
			{
				key: "Strict-Transport-Security",
				value: "max-age=63072000; includeSubDomains; preload",
			},
			{
				key: "X-XSS-Protection",
				value: "1; mode=block",
			},
			{
				key: "X-Frame-Options",
				value: "SAMEORIGIN",
			},
			{
				key: "Permissions-Policy",
				value: `camera=(), microphone=(), geolocation=(self ${process.env.NEXT_PUBLIC_BASE_URL})`,
			},
			{
				key: "X-Content-Type-Options",
				value: "nosniff",
			},
			{
				key: "Referrer-Policy",
				value: "origin-when-cross-origin",
			},
			{
				key: "Content-Security-Policy",
				value: `frame-ancestors ${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL} https://mdmpreprod.onecloud.ma`,
			},
		],
	},
}

import { useRef } from "react"
import clsx from "clsx"
import { Link, Icon } from "@/ui"
import { useRouter } from "next/router"
import projectConfigs from "../../../../project.config"
export const config = {
	id: "mre_templates:toolbox",
}

export const ToolBox = ({ items, sticky, className = "" }) => {
	const toolboxRef = useRef(null)
	const router = useRouter()
	const currentPath = `/${router.locale}${router.asPath}`

	// Define the list of excluded URL prefixes
	const externallangs = projectConfigs?.externalLangs
	const excludedLanguages = externallangs.map((lang) => `/${lang}/`)

	// Check if the current URL starts with any of the excluded prefixes
	const shouldHideToolBox = excludedLanguages.some((lang) => currentPath.includes(lang))

	// If the URL matches an excluded language, don't render the component
	if (shouldHideToolBox) {
		return null
	}
	return (
		<div
			ref={toolboxRef}
			className={clsx(
				sticky
					? "upToTablet:fixed upToTablet:bottom-0 upToTablet:right-0 upToTablet:left-0 upToTablet:z-30 upToTablet:shadow"
					: "fixed z-50 md:z-[56] left-0 w-full bottom-0 md:top-1/2 md:-translate-y-1/2 md:left-[calc(100%-50px)]",
				"onlyDesktop:fixed onlyDesktop:z-10 onlyDesktop:left-[calc(100%-50px)] transition-all ease duration-100 h-fit",
				className
			)}
		>
			<ul
				className={clsx(
					sticky
						? "upToTablet:flex upToTablet:flex-row"
						: "md:flex md:flex-col grid grid-cols-3 items-start w-full md:w-auto md:gap-px gap-0",
					"onlyDesktop:flex onlyDesktop:flex-col onlyDesktop:items-start md:bg-transparent bg-primary text-black divide-x md:divide-transparent divide-black"
				)}
			>
				{items.map((item) => {
					return (
						<li
							key={item.id}
							className={clsx(
								sticky
									? "upToTablet:shrink-1 upToTablet:grow upToTablet:basis-0"
									: "md:mb-px mb-0 whitespace-nowrap translate-x-0 md:hover:translate-x-[calc(-100%+50px)] md:rtl:hover:translate-x-[calc(100%-50px)]",
								"rounded-none onlyDesktop:mb-4 onlyDesktop:whitespace-nowrap onlyDesktop:shadow onlyDesktop:translate-x-0 onlyDesktop:hover:translate-x-[calc(-100%+50px)] rtl:onlyDesktop:hover:translate-x-[calc(100%-50px)]",
								"transition-all bg-primary  md:w-auto w-full h-full",
								item.hiddenMobile && "upToTablet:hidden"
							)}
						>
							<Link
								id={item.id}
								href={item.url}
								target={item.target}
								rel={item.rel}
								className={clsx(
									"rounded-none h-full flex flex-col md:flex-row items-center justify-start p-2 md:p-0 md:pr-3 md:w-40 box-content",
									sticky
										? "upToTablet:flex-col upToTablet:h-full upToTablet:border-x upToTablet:border-yellow-500 h-full"
										: "flex-row",
									"onlyDesktop:flex-row",
									item.className
								)}
							>
								<span
									className={clsx(
										"inline-flex justify-center items-center md:h-[50px] h-[25px] w-[25px]  md:w-[50px]"
									)}
								>
									<Icon
										id={item.icon}
										className="w-4 h-4 mb-2 md:mb-0 md:p-5 box-content flex-shrink-0"
									/>
								</span>
								<span
									className={clsx(
										sticky && "upToTablet:px-2 upToTablet:text-center",
										"block-inline text-[9px] md:text-xs md:font-bold leading-tight line-clamp-2",
										"onlyDesktop:py-3 onlyDesktop:pr-3 whitespace-normal"
									)}
								>
									{item.title}
								</span>
							</Link>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

const ToolBoxContainer = ({ data }) => {
	const props = {
		sticky: data?.extra_field?.sticky,
		items: data?.components?.map((item) => ({
			hiddenMobile: item?.hide,
			active: item?.active,
			icon: item?.icon,
			title: item?.link?.title,
			url: item?.link?.url,
			id: item?.link?.attributes?.id,
			className: item?.link?.attributes?.class,
			target: item?.link?.attributes?.target,
			rel: item?.link?.attributes?.rel,
		})),
	}

	return <ToolBox {...props} />
}

export default ToolBoxContainer
